import React, { useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import { Row, Col, Button, Card, Media, Table, Modal } from 'react-bootstrap'
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Animation from '../components/animate.js'
import Banner from '../components/banner'
import Common from '../components/common'
import Footer from '../components/footer'
import CommonFlex from '../components/commonFlex'
import Trade from '../components/trade'
import Hexagon from '../components/hexagon'
import Consult from '../components/consult'
import Markdown from "react-markdown"

import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'
import { useStaticQuery, graphql } from "gatsby"

import './../components/media.css'
import './../assets/css/index.css'
import './../assets/css/ecology.css'

// 引入此路径，才不会打包失败
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const data = [
  {
    title: '驱动全面化',
    url: require('./../images/base11.png'),
    img: require('./../images/base1.png'),
    href: '#driver',
  },
  {
    title: '低代码流程图',
    url: require('./../images/base22.png'),
    img: require('./../images/base2.png'),
    href: '#dashboard',
  },
  {
    title: '智能报表',
    url: require('./../images/base33.png'),
    img: require('./../images/base3.png'),
    href: '#report',
  },
  {
    title: '智能报警',
    url: require('./../images/base44.png'),
    img: require('./../images/base4.png'),
    href: '#warning',
  }
]

const engineData = [
  {
    title: '数据采集与控制引擎',
    desc: '海量工业设备驱动库，实现快速的设备接入、报警、自动化控制及远程控制，具备完整的SCADA功能。',
    url: require('./../images/engine1.png')
    // url: require('./../images/yinqin1.jpg')
  },
  {
    title: '可视化组态引擎',
    desc: '丰富可视化看板及组件选择，满足各类信息展示场景、工艺流程图、数据可视化场景。自定义行业展示，支持三维、SVG、图表，搭建各类专属页面，实现千人千面。',
    url: require('./../images/engine2.png')
  },
  {
    title: '数据分析引擎',
    desc: '灵活报表曲线，满足各类型数据分类及分析需求；毫秒级数据反馈速度， 快速响应客户分析条件变换查询需求。',
    url: require('./../images/engine3.png')
  },
  {
    title: '业务流引擎',
    desc: '创新强悍的流程引擎，为业务高效流转提供了坚实的技术支持，可视化的流程设计界面，无需代码即可轻松创建并管理业务流程、数据处理流程、事件报警流程等。',
    url: require('./../images/engine4.png')
  },
  {
    title: '二次开发引擎',
    desc: '提供丰富的前端、后台服务二次开发接口，具备灵活的组件服务部署与管理能力，几乎可以对任何功能模块进行二次开发，满足客户各类二次开发需求。',
    url: require('./../images/engine5.png')
  }
]

let scenarioCase = [
  {
    title: '智慧油田',
    bg_image: require('./../images/智慧油田.png') ,
    title_image: require('./../images/油田.png') 
  },
  {
    title: '智慧城市',
    bg_image: require('./../images/智慧城市.png') ,
    title_image: require('./../images/城市.png') 
  },
  {
    title: '车联网',
    bg_image: require('./../images/车联网.png') ,
    title_image: require('./../images/车联网l.png') 
  },

  {
    title: '数字油库',
    bg_image: require('./../images/数字油库.png') ,
    title_image: require('./../images/油库.png') 
  },
  {
    title: '智慧水厂',
    bg_image: require('./../images/智慧水厂.png') ,
    title_image: require('./../images/水厂.png') 
  },
  {
    title: '智慧工厂',
    bg_image: require('./../images/智慧工厂.png') ,
    title_image: require('./../images/工厂.png') 
  }
]

const IndexPage = () => {
  const [hash, setHash] = React.useState(null)
  const [recomments, setRecomments] = React.useState([])
  const [activeIndex, setActiveIndex] = React.useState(1)
  const [swipers, setSwipers] = React.useState(null)
  const [agent, setAgent] = React.useState(true)
  const [videoBg, setVideoBg] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const swiperRef = useRef()
  const videoRef = useRef()
  const [ initPerView, setPerView ] = React.useState(4)
  const [ height, setHeight ] = React.useState()
  let jzt;
  if (typeof window !== "undefined") { 
    jzt = window.document.getElementById('partner-img-jzt')
  }

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()


    if (typeof window !== "undefined") {
      if (window.location.hash && window.location.hash.indexOf('#') > -1) {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#') + 1))
      }

      window.addEventListener('resize', (event) => {
        let jztHeight = document.getElementById('partner-img-jzt').height ? document.getElementById('partner-img-jzt').height + 50 : 400
        setHeight(jztHeight)
      })

      let jztHeight = document.getElementById('partner-img-jzt').height ? document.getElementById('partner-img-jzt').height + 50 : 400
      setHeight(jztHeight)
    }
    setAgent(check())
  }, [])

  React.useEffect(() => {
    let jztHeight = document.getElementById('partner-img-jzt').height ? document.getElementById('partner-img-jzt').height + 50 : 400
    setHeight(jztHeight)
  }, [ jzt ])

  console.log(height, 'gaduuu')
  React.useEffect(() => {
    if (hash) {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('engineHead');
      window.scrollTo({
        top: product_media[0].offsetTop - 150
      })
    }
  }, [hash])

  const getCookie = (name) => {
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] == name) {
        return arr[1];
      }
    }
    return "";
  }

  const downPlat = () => {
    let cookieData = getCookie('data')
    if (cookieData && JSON.parse(cookieData)) {
      let contact_name = JSON.parse(cookieData).contact_name
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if")
    } else {
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if")
    }
  }

  const data = [
    {
      button_desc: '申请加入AIRIOT生态合作计划',
      button_title: '马上加入',
      url: require('./../images/line-start.png'),
      href: 'https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if',
      id: 'start',
      target: true
    },
    {
      button_desc: '下载AIRIOT生态合作伙伴手册',
      button_title: '立即下载',
      url: require('./../images/line-end.png'),
      href: 'http://airiot.cn/strapi/uploads/AIRIOT_1944ce53fb.pdf',
      id: 'start',
      target: true,
      download: true,
      fileName: 'AIRIOT生态合作伙伴计划'
    }
  ]

  return (
    <div className="home-product-sp">
      <div className="home-index home-nav-fix">
        <Header />
      </div>
          <div className="home-bg home-index home-ecology" style={{ position: 'relative', background: `url(${require("./../images/banner@2x.png")})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', height: '34.375rem' }}>
          <Layout typePage="home">
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
            <div className="engine-plat">
              <div className="plat-jumbotron">
                  <div className="jumbotron-left">
                      <div className="jumbotron-title">行业专家智库</div>
                      <div className="jumbotron-desc">业内精英聚集地，赋能生态及AIRIOT金字塔服务体系。</div>
                      <div className="jumbotron-btn-border">
                        <Button variant="primary" className="engine-button" onClick={() => downPlat()}>成为AIRIOT生态合作伙伴</Button>
                      </div>
                  </div>
              </div>
              <div className="jumbotron-right" style={{ height: 0 }}>
                  {/* <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img> */}
              </div>
            </div> 
          </Layout>
        </div>
      <Consult />
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <CommonFlex animate="animate1" title="专家资源赋能生态成长" desc=""></CommonFlex>
        <Row className="scenario-case">
          <Col><img className="auth-partner" src={require('./../images/专家资源赋能生态成长.png')}/></Col>
        </Row>
      </Container>
      <div>
        <CommonFlex animate="animate1" title="AIRIOT金字塔服务体系" desc=""></CommonFlex>
        <div className="jztbjbox">
          <div className="jztbj" style={{ height }}></div>
          <div className="jztbj-secord">
              <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row className="scenario-case">
                  <Col><img id="partner-img-jzt" className="auth-partner" src={require('./../images/金字塔.png')}/></Col>
                </Row>
              </Container>
            </div>
        </div>
      </div>
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <CommonFlex animate="animate5" title="开启AIRIOT生态合作之旅" desc=""></CommonFlex>
        <div className="start-air" style={{ marginBottom: '5rem' }}>
          <img src={require('./../images/left-top.png')} className="left-zs" />
          <Common style="30.75rem" shadowFlag={true} data={data}></Common>
        </div>
      </Container>
      <Footer></Footer>
    </div>

  )
}


export default IndexPage
